<template>
  <div>
    <div
      class="d-flex flex-wrap align-items-center flex-md-nowrap flex-md-row"
      style="row-gap: .5rem; column-gap: 1rem;"
    >
      <b-button
        class="btn-icon mr-50"
        variant="outline-secondary"
        size="sm"
        @click="getPolls"
      >
        <feather-icon icon="RefreshCwIcon" />
      </b-button>

      <b-button
        v-if="checkRequiredPermissions([$roles.CONTACT_THREAD_POLL_MODIFY])"
        variant="primary"
        size="sm"
        @click="isModalOpen = true"
      >
        <feather-icon icon="PlusIcon" />
        {{ $t('NewPoll') }}
      </b-button>

      <h4
        v-if="$slots.title"
        class="mb-0"
      >
        <slot name="title" />
      </h4>
    </div>
    <b-row>
      <b-col
        v-if="loading"
        sm="12"
      >
        <div class="d-flex py-3 justify-content-center">
          <b-spinner variant="primary" />
        </div>
      </b-col>

      <b-col
        v-else-if="!loading && !polls.length"
        sm="12"
      >
        <div class="text-center py-3 text-primary font-weight-bold">
          <feather-icon
            size="17"
            icon="XCircleIcon"
          />
          {{ $t('NoData') }}
        </div>
      </b-col>

      <b-col
        v-else
        sm="12"
      >
        <div
          v-for="(poll, pollIndex) in polls"
          :key="`poll_${poll.id}`"
          class="d-flex align-items-start"
        >
          <app-collapse
            accordion
            class="flex-grow-1 mt-50 position-relative"
            type="border"
          >
            <b-overlay
              :show="poll.loading"
              no-wrap
              spinner-variant="primary"
            />

            <app-collapse-item
              show-actions
              :show-delete="checkRequiredPermissions([$roles.CONTACT_THREAD_POLL_DELETE])"
              :show-edit="checkRequiredPermissions([$roles.CONTACT_THREAD_POLL_MODIFY])"
              sub-title
              :title="poll.poll.name"
              show-download
              @edit="isModalOpen = true; editedPoll = { poll, index: pollIndex }"
              @delete="removePoll(poll, pollIndex)"
              @download="downloadPoll(poll, pollIndex)"
            >
              <template #subTitle>
                <div class="mt-50 text-muted d-flex flex-column flex-md-row align-items-center">
                  <div
                    v-b-tooltip
                    class="pr-md-2 d-flex align-items-center flex-column"
                    :title="$t('CreatedAt')"
                  >
                    <avatar :user="poll.createdBy" />
                    <div>
                      <feather-icon
                        icon="PlusIcon"
                        class="ml-25"
                      />
                      <span class="ml-50">{{ formatDate(poll.createdAt.date, { month: 'numeric', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' }, $i18n.locale) }}</span>
                    </div>
                  </div>

                  <div
                    v-b-tooltip
                    class="mt-1 mt-md-0 d-flex align-items-center flex-column"
                    :title="$t('UpdatedAt')"
                  >
                    <avatar :user="poll.updatedBy" />
                    <div>
                      <feather-icon
                        icon="EditIcon"
                        class="ml-25"
                      />
                      <span class="ml-50">{{ formatDate(poll.updatedAt.date, { month: 'numeric', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' }, $i18n.locale) }}</span>
                    </div>
                  </div>
                </div>
              </template>

              <b-row>
                <b-col
                  v-for="(field, index) in [...getFilteredPollFields(poll.contactThreadPollFields, poll)]"
                  :key="`field_${poll.id}_${index}`"
                  md="6"
                  class="breaker"
                >
                  <div class="border-bottom d-flex py-50">
                    <div
                      v-b-tooltip
                      class="font-weight-bolder text-truncate flex-grow-1 text-primary"
                      style="word-break: break-word; white-space: pre-wrap"
                      :title="field.pollField.translations[0].name"
                    >
                      {{ field.pollField.translations[0].name | truncate(50) }}
                    </div>
                    <div v-if="Array.isArray(field.pollFieldOptions) && field.pollFieldOptions.length">
                      <b-badge
                        v-for="(option, optionIndex) in field.pollFieldOptions"
                        :key="`field_options_${pollIndex}_${optionIndex}_${index}`"
                      >
                        {{ option.translations[0].name }}
                      </b-badge>
                    </div>
                    <div v-else-if="field.pollField.type !== 'CHECKBOX' && field.value">
                      {{ field.value }}
                    </div>
                    <div v-else-if="field.pollField.type === 'CHECKBOX'">
                      <b-form-checkbox
                        variant="primary"
                        :checked="Boolean(Number(field.value))"
                        disabled
                      />
                    </div>
                    <div v-else>
                      <feather-icon icon="MinusIcon" />
                    </div>
                  </div>
                </b-col>

                <b-col sm="12" @click="poll.showMore = !poll.showMore" class="text-center">
                  <div class="text-primary pt-1 mt-50 cursor-pointer">{{ !poll.showMore ? $t('ShowUnfilledFields') : $t('HideUnfilledFields') }}</div>
                </b-col>
              </b-row>
            </app-collapse-item>
          </app-collapse>
        </div>
      </b-col>
    </b-row>

    <!-- POLL PDF TEMPLATE -->
    <div
      id="container-html"
      :style="{ visibility: 'hidden' }"
    >
      <div ref="html2Pdf">
        <b-row
          v-if="selectedPoll"
          id="html2Pdf-row"
        >
          <b-col sm="12">
            <h3>{{ selectedPoll.poll.name }}</h3>
          </b-col>
          <b-col
            v-for="(field, index) in [...getFilteredPollFields(selectedPoll.contactThreadPollFields, selectedPoll)]"
            :key="`field_${selectedPoll.id}_${index}`"
            md="6"
            class="breaker"
          >
            <div class="border-bottom py-50">
              <div
                class="font-weight-bolder text-truncate text-primary"
                style="word-break: break-word; white-space: pre-wrap"
              >
                {{ field.pollField.translations[0].name | truncate(50) }}
              </div>
              <div v-if="Array.isArray(field.pollFieldOptions) && field.pollFieldOptions.length">
                <b-badge
                  v-for="(option, optionIndex) in field.pollFieldOptions"
                  :key="`field_options_preview_${optionIndex}_${index}`"
                  class="mr-25"
                >
                  {{ option.translations[0].name }}
                </b-badge>
              </div>
              <div v-else-if="field.pollField.type !== 'CHECKBOX' && field.value">
                {{ field.value }}
              </div>
              <div v-else-if="field.pollField.type === 'CHECKBOX' && field.value">
                <b-form-checkbox
                  variant="primary"
                  :checked="Boolean(Number(field.value))"
                  disabled
                />
              </div>
              <div v-else>
                <feather-icon icon="MinusIcon" />
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>

    <b-modal
      :visible="isModalOpen"
      :title="$t('Poll')"
      hide-footer
      no-close-on-backdrop
      size="lg"
      body-class="new-poll-modal"
      @hide="isModalOpen = false; editedPoll = { poll: null, index: -1 }"
    >
      <new-poll-modal
        :disabled-polls="polls.map(poll => poll.poll.id)"
        :edited-poll="editedPoll.poll"
        @save-item="saveItem"
      />
    </b-modal>
  </div>
</template>

<script>
/* eslint-disable */
import NewPollModal from '@/views/modals/NewPollModal.vue'
import { mapGetters } from 'vuex'
import { CREATE_POLL, DELETE_POLL, GET_POLLS, SAVE_POLL_OPTION, } from '@/@constants/mutations'
import { formatDate } from '@core/utils/filter'
import { VBTooltip } from 'bootstrap-vue'
import html2pdf from 'html2pdf.js'

export default {
  name: 'Polls',
  components: {
    NewPollModal,
  },
    props: {
      thread: {
          type: Object,
          default: null
      },
    },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data: () => ({
    polls: [],
    loading: false,
    isModalOpen: false,
    editedPoll: { poll: null, index: -1 },
    selectedPoll: null,
  }),
  computed: {
    ...mapGetters({
      globalThread: 'singleContact/getSelectedThread',
    }),
      fullThread() {
        return this.thread || this.globalThread
      },
  },
  mounted() {
    this.getPolls()
  },
  methods: {
    formatDate,
    downloadPoll(poll, index) {
      this.$set(this.polls[index], 'loading', true)
      this.selectedPoll = poll

      this.$nextTick(() => {
        setTimeout(async () => {
          await html2pdf(this.$refs.html2Pdf, {
            margin: [15, 15],
            filename: `${poll.poll.name}.pdf`,
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2, letterRendering: true },
            jsPDF: { unit: 'pt', format: 'letter', orientation: 'portrait' },
            pagebreak: { mode: ['avoid-all', 'css', 'legacy'] },
          })

          this.selectedPoll = null
          this.$set(this.polls[index], 'loading', false)
        }, 1000)
      })
    },
    getPolls() {
      this.loading = true
      const { contact, id } = this.fullThread
      const payload = {
        contactId: contact.id, threadId: id,
      }

      this.$store.dispatch(`singleContact/${GET_POLLS}`, payload)
        .then(res => {
          this.polls = res.data.items.map(poll => ({ ...poll, contactThreadPollFields: poll.contactThreadPollFields.sort((a, b) => a.pollField.position - b.pollField.position), loading: false, showMore: false }))
          this.loading = false
        })
        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
          this.loading = false
        })
    },
      getFilteredPollFields(fields = [], poll) {
        const _ = (field) => (
            (Array.isArray(field?.pollFieldOptions) && field?.pollFieldOptions.length) ||
            (field?.pollField?.type !== 'CHECKBOX' && field?.value) ||
            (field?.pollField?.type === 'CHECKBOX')
        )
          // Array.isArray(field.pollFieldOptions) && field.pollFieldOptions.length
          // field.pollField.type !== 'CHECKBOX' && field.value
          // field.pollField.type === 'CHECKBOX'
        if (poll.showMore) return fields

        return fields.filter(field => _(field))
      },
    saveItem(fields, poll) {
      const { contact, id } = this.fullThread

      const payload = {
        contactId: contact.id, threadId: id, pollId: typeof poll === 'string' ? poll : poll?.id,
      }

      if (!this.editedPoll.poll) {
        this.$store.dispatch(`singleContact/${CREATE_POLL}`, payload)
          .then(res => {
            const threadPollId = res.data.items[0]?.id
            if (threadPollId) {
              this.saveFields(threadPollId, fields)
            }
          })
          .catch(err => {
            this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
          })
      } else this.saveFields(payload.pollId, fields)
    },
    saveFields(pollId, fields) {
      const { contact, id } = this.fullThread

      const payload = {
        contactId: contact.id, threadId: id, pollId, options: fields,
      }

      this.$store.dispatch(`singleContact/${SAVE_POLL_OPTION}`, payload)
        .then(() => {
          this.isModalOpen = false
          this.editedPoll = { poll: null, index: -1 }
          this.showToast('success', this.$i18n.t('success.contactUpdated'))
          this.getPolls()
        })
        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })
    },
    removePoll(poll, index) {
      this.showAlert('error', this.$i18n.t('alerts.AreYouSure'), this.$i18n.t('alerts.WontbeAble'))
        .then(() => {
          const { contact, id } = this.fullThread

          const payload = {
            contactId: contact.id, threadId: id, pollId: poll.id,
          }
          this.$store.dispatch(`singleContact/${DELETE_POLL}`, payload)
            .then(() => {
              this.$delete(this.polls, index)
              this.showToast('success', this.$i18n.t('RemovedSuccessfully'))
            })
            .catch(err => {
              this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
            })
        })
    },
  },
}
</script>

<style lang="scss">
.new-poll-modal {
  .tooltip {
    .tooltip-inner{
      white-space: pre-wrap;
      width: 400px !important;
      max-width: 400px !important;
    }
  }
}
</style>
